<template>
    <v-container class="panel-wrapper panel-events pa-0" grid-list-lg fluid >
        <v-layout column>
            <v-flex>
                <TabCard title="Events">
                    <v-layout column>
                        <v-flex>
                            <p style="color: #666;margin: 0;" class="fs-3 font-weight-regular ">
                                The following are events pertaining to this {{appInfo.product_name}} deployment.
                            </p>
                        </v-flex>
                    </v-layout>
                    <v-layout wrap class="search-wrapper">
                        <!--<v-flex style="width: 200px;" shrink>-->
                        <!--<v-select v-model="selected" :items="items" solo hide-details dense></v-select>-->
                        <!--</v-flex>-->
                        <!--<v-flex style="width: 200px;" shrink>-->
                        <!--<v-select v-model="selected" :items="items" solo hide-details dense></v-select>-->
                        <!--</v-flex>-->
                        <v-flex style="width: 260px;" shrink>
                            <v-text-field
                                    :disabled="loading"
                                    v-model.trim="searchKey"
                                    @click:append="searchList"
                                    placeholder="Search"
                                    append-icon="search"
                                    @keyup.enter="searchList"
                            ></v-text-field>
                        </v-flex>
                        <v-flex shrink>
                            <v-btn class="text-none" :loading="loading" :disabled="!searchKey" color="primary" width="40" style="margin: 0px;" @click="clear">Clear</v-btn>
                        </v-flex>
                    </v-layout>
                    <v-layout column>
                        <v-flex>
                            <v-data-table
                                    :headers="headers"
                                    :items="list"
                                    :loading="loading"
                                    hide-actions
                                    class="elevation-1">
                                <v-progress-linear v-slot:progress color="blue" indeterminate></v-progress-linear>
                                <template v-slot:items="{item}">
                                    <td  class="text-xs-left">{{formatDate(item.created_date ,'MM/DD/YYYY HH:mm')  }}</td>
                                    <td class="text-xs-right">{{ item.subject || '' }}</td>
                                </template>
                                <template v-slot:no-data>
                                    <div style="min-height: 192px;position: relative;" >
                                        <Spin v-show="loading"></Spin>
                                        <Empty v-show="!loading"></Empty>
                                    </div>
                                </template>
                            </v-data-table>
                        </v-flex>
                    </v-layout>
                    <v-layout style="margin-top: 20px;">
                        <v-spacer></v-spacer>
                        <v-flex>
                            <v-pagination
                                    :disabled="loading"
                                    @input="changePage"
                                    v-model="page"
                                    :length="page_total"
                            ></v-pagination>
                        </v-flex>
                    </v-layout>
                </TabCard>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    import Spin from '@/components/Spin.vue'
    import Empty from '@/components/Empty.vue'
    import TabCard from '@/components/TabCard.vue'
    import {mapState, mapGetters} from 'vuex' //mapActions  mapGetters

    import {formatDate} from '@/module/utils/date'

    export default {
        name: "Activities",
        components: {Spin, Empty, TabCard},
        data() {
            return {
                loading:false,
                headers: [
                    {text: 'Timestamp', value: 'time', sortable: false, align: 'left'},
                    {text: 'Details', value: 'platform', sortable: false, align: 'right',},
                ],
                list: [],
                searchKey: '',
                page: 1,
                page_total: 0
            }
        },
        computed: {
            ...mapState('application', ['appInfo']),
            ...mapGetters('application', ['getServiceId'])
        },
        methods: {
            formatDate,
            clear(){
                this.searchKey= ''
                this.getList(1)
            },
            getList(page, searchKey = this.searchKey) {
                if(this.loading){
                    return
                }
                this.loading=true
                if (page) {
                    this.page = page
                }
                return this.$http.get('/activities/', {
                    params: {
                        page: this.page,
                        page_size: 10,
                        service_uuid: this.getServiceId(this.appInfo),
                        search :searchKey
                    }
                }).then((res) => {
                    this.page_total = res.page_total
                    this.list = res.results
                    this.loading = false
                }).catch(() => {
                    this.$message.error('Oops, something bad happened while listing events, please contact us at support@cloudclusters.io for this issue.')
                    this.loading = false
                })
            },
            changePage(page){
                this.getList(page)
            },
            searchList(){
                this.getList(1)
            }
        },
        created() {
            this.getList()
            this.$SDK.track({pageName:'Events',productName:this.appInfo.product_name})
        }
    }
</script>

<style lang="scss">
    .panel-events {
        .search-wrapper {
            .v-text-field {
                padding-top: 0;
            }
            .v-text-field--solo .v-input__control {
                min-height: 36px;
            }
        }
    }

</style>
