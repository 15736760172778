var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    {
      staticClass: "panel-wrapper panel-events pa-0",
      attrs: { "grid-list-lg": "", fluid: "" }
    },
    [
      _c(
        "v-layout",
        { attrs: { column: "" } },
        [
          _c(
            "v-flex",
            [
              _c(
                "TabCard",
                { attrs: { title: "Events" } },
                [
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c("v-flex", [
                        _c(
                          "p",
                          {
                            staticClass: "fs-3 font-weight-regular ",
                            staticStyle: { color: "#666", margin: "0" }
                          },
                          [
                            _vm._v(
                              "\n                            The following are events pertaining to this " +
                                _vm._s(_vm.appInfo.product_name) +
                                " deployment.\n                        "
                            )
                          ]
                        )
                      ])
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticClass: "search-wrapper", attrs: { wrap: "" } },
                    [
                      _c(
                        "v-flex",
                        {
                          staticStyle: { width: "260px" },
                          attrs: { shrink: "" }
                        },
                        [
                          _c("v-text-field", {
                            attrs: {
                              disabled: _vm.loading,
                              placeholder: "Search",
                              "append-icon": "search"
                            },
                            on: {
                              "click:append": _vm.searchList,
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.searchList($event)
                              }
                            },
                            model: {
                              value: _vm.searchKey,
                              callback: function($$v) {
                                _vm.searchKey =
                                  typeof $$v === "string" ? $$v.trim() : $$v
                              },
                              expression: "searchKey"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { shrink: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              staticStyle: { margin: "0px" },
                              attrs: {
                                loading: _vm.loading,
                                disabled: !_vm.searchKey,
                                color: "primary",
                                width: "40"
                              },
                              on: { click: _vm.clear }
                            },
                            [_vm._v("Clear")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { attrs: { column: "" } },
                    [
                      _c(
                        "v-flex",
                        [
                          _c(
                            "v-data-table",
                            {
                              staticClass: "elevation-1",
                              attrs: {
                                headers: _vm.headers,
                                items: _vm.list,
                                loading: _vm.loading,
                                "hide-actions": ""
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "items",
                                  fn: function(ref) {
                                    var item = ref.item
                                    return [
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-left" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.formatDate(
                                                item.created_date,
                                                "MM/DD/YYYY HH:mm"
                                              )
                                            )
                                          )
                                        ]
                                      ),
                                      _c(
                                        "td",
                                        { staticClass: "text-xs-right" },
                                        [_vm._v(_vm._s(item.subject || ""))]
                                      )
                                    ]
                                  }
                                },
                                {
                                  key: "no-data",
                                  fn: function() {
                                    return [
                                      _c(
                                        "div",
                                        {
                                          staticStyle: {
                                            "min-height": "192px",
                                            position: "relative"
                                          }
                                        },
                                        [
                                          _c("Spin", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.loading,
                                                expression: "loading"
                                              }
                                            ]
                                          }),
                                          _c("Empty", {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: !_vm.loading,
                                                expression: "!loading"
                                              }
                                            ]
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  },
                                  proxy: true
                                }
                              ])
                            },
                            [
                              _c("v-progress-linear", {
                                attrs: { color: "blue", indeterminate: "" },
                                scopedSlots: _vm._u([
                                  {
                                    key: "progress",
                                    fn: function() {
                                      return undefined
                                    },
                                    proxy: true
                                  }
                                ])
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-layout",
                    { staticStyle: { "margin-top": "20px" } },
                    [
                      _c("v-spacer"),
                      _c(
                        "v-flex",
                        [
                          _c("v-pagination", {
                            attrs: {
                              disabled: _vm.loading,
                              length: _vm.page_total
                            },
                            on: { input: _vm.changePage },
                            model: {
                              value: _vm.page,
                              callback: function($$v) {
                                _vm.page = $$v
                              },
                              expression: "page"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }